body {
  margin: 0;
  padding: 0;
}
.App {
  display: flex;
}
main {
  padding: 0px;
  width: 4000px;
}

.App {
  font-family: sans-serif;
  text-align: center;
}

body {
  background-color: #1f1f1f;
  color: #f1f1f1;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
